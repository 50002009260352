/* 表演验证，无刷新提交 企业绑定*/

import Vue from 'vue'
// import layer from 'vue-layer'
import layer from "layui-layer";
import 'vue-layer/lib/vue-layer.css'
var wx = require('weixin-js-sdk')

import $ from '../../../assets/jquery-3.6.0.min.js'

import '../../../plugins/wenjuan/validationEngine.jquery.css'
import '../../../plugins/wenjuan/jquery.form.min.js'
import '../../../plugins/wenjuan/jquery.validationEngine.js'
import '../../../plugins/wenjuan/jquery.validationEngine-zh_CN.js'
import '../../../assets/bootstrap.min.css';


/**
 * 调用函数
 */
function callFun(fn, args) {
	try {
		fn = eval(fn);
	} catch (e) {
		console.log(e);
	}
	if (typeof fn === 'function') {
		fn.call(this, args);
	}
}

/**
 * 手机段
 * @param msg
 */
var msg = function (msg) {
	//layer.msg(fn + '方法不存在！');
	layer.msg(msg);
};

$().ready(function () {
	const getLocationParams = (keyWords) => {
		// 提取路由值（字符串）
		let href = window.location.href;
		// 从占位符开始截取路由（不包括占位符）
		let query = href.substring(href.indexOf("?") + 1);
		// 根据 & 切割字符串
		let vars = query.split("&");
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split("=");
			// 根据指定的参数名去筛选参数值
			if (pair[0] == keyWords) {
				return pair[1];
			}
		}
		return "没有该参数信息";
	};
	console.log(window.location);
	let cid = getLocationParams("cid");
	let uid = getLocationParams("uid");
	let v = getLocationParams("v");
	console.log('v',v);
	let wjdoId = getLocationParams("wjdoId");
  var url
	//if(cid == 0){
		url = "https://v2.wenjuan.ak.sdcom.gov.cn/mp/view/company/create/" +uid + '?v='+v
	// }else{
	// 	url = "https://v2.wenjuan.ak.sdcom.gov.cn/mp/view/survey/view/" +uid + '/'+cid + '/'+wjdoId
	// }
	console.log('url',url);

	var options = {
		//target:        '#output',
		beforeSubmit: showRequest,
		success: showResponse,
		url,
		type: 'post',
		dataType: 'json',
		clearForm: false,
		timeout: 1000
	};

	$(".sniperForm").submit(function () {
		layer.msg("数据准备提交", { time: 1000 });
		console.log('options',options);
		//判断提交不为空
		console.log($(".sniperForm").serializeArray())
		const list = $(".sniperForm").serializeArray()
		if(list.length){
		  $(this).ajaxSubmit(options);
		}else{
		  layer.msg("提交失败，请重新提交");
		}
		return false;
	});

	$(".sniperForm").validationEngine('attach', {
		promptPosition: "bottomLeft",
		//每次只显示一个错误,加快速度
		showOneMessage: true
	});

	// pre-submit callback
	function showRequest(formData, jqForm, options) {
		if ($(".sniperForm").validationEngine('validate')) {
			$(".sniperForm button").attr("disabled", true);
			if (confirm("提交数据?")) {
				layer.msg("稍等...", { time: 1000 });
				console.log('提交');
				return true;
			} else {
				$(".sniperForm button").removeAttr("disabled");
			}
		}
		return false;
	}

	// post-submit callback
	function showResponse(responseText, statusText, xhr, $form) {
		console.log('responseText',responseText);
		console.log('statusText',statusText);
		var _Vue = Vue
		//关闭所有窗口
		if (statusText === 'success') {
			layer.alert(responseText.message, {
				skin: 'layui-layer-molv'
				, closeBtn: 0
			}, function (index) {
			  layer.close(index);
				//数据回调
				if (responseText.successCallBack) {
					console.log('responseText.successCallBack',responseText.successCallBack, responseText.object);
					callFun(responseText.successCallBack, responseText.object)
				}

				if (responseText.failCallBack) {
					console.log('responseText.failCallBack',responseText.failCallBack, responseText.object);
					callFun(responseText.failCallBack, responseText.object)
				}
				console.log('responseText',responseText);
				if (responseText.status === 200) {
					// if (responseText.backUrl) {
					// 	let urls = location.href
					// 	let newUrl = "v2.wenjuan.harbor.sdcom.gov.cn"
					// 	let oldUrl = 'wmjc.admin.sdcom.gov.cn'
					// 	let backUrl = urls.replace(oldUrl,newUrl)
						
					// 	console.log('backUrl',backUrl,urls);
					// 	setTimeout("window.location.href=\"" + backUrl + "\"", responseText.timeout);
					// } else if (responseText.reload) {
					// 	setTimeout("window.location.reload(false)", responseText.timeout);
					// }
					//wx.miniProgram.navigateTo({ url: '/pages/wenjuan/index' });
					window.saveSuccessBack
				} else {
					$(".sniperForm button").attr("disabled", false);
					$(document).scrollTop(0);
				}
			});

		} else {
			$(".sniperForm button").attr("disabled", false);
			layer.msg("500");
		}
	}

});