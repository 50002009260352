<template>
	<div class="container pt-2 pb-2">
		<div class="new-company-user" id="app">
			<div class="">
				<div class="portlet-body form">
					<form class="form-horizontal sniperForm" role="form" id="id_frm_new_company_user" method="post"
						onsubmit="return false">
						<input name="${_csrf.parameterName!''}" value="${_csrf.token!''}" type="hidden">
						<div class="mb-3">
							<label for="name" class="form-label">企业名称</label>
							<input type="text" name="name" class="form-control validate[required]" placeholder="《营业执照》中的全称"
								@input="getCompany" v-model="company.name">
						</div>

						<div class="mb-3">
							<label class="form-label">社会统一信用代码：</label>
							<input type="text" name="uscc" class="form-control validate[required]" placeholder="18位代码"
								maxlength="18" v-model="company.uscc">
						</div>
						<div class="mb-3">
							<label class="form-label">企业海关代码：<small>外贸企业请填写</small></label>
							<input type="text" name="customsCode" class="form-control" placeholder="10位代码" maxlength="10"
								v-model="company.customsCode">
						</div>
						<!--                       这里的city是code+name绑定提交格式需要后台抽取 -->
						<div class="mb-3">
							<label class="form-label">企业所在地：</label>
							<div class="row">
								<div class="col-4">
								<select name="cityCode" style="width: 100%;"  v-model="cityCode" class="form-select validate[required]">
									<option v-for="(c,inx) in cityList" :value="c.areaCode + ',' + c.areaName" :key="inx">
										{{ c.areaName }}
									</option>
									</select>
								</div>

								<div class="col-4">
									<select name="countyCode" style="width: 100%;" v-model="countyCode" class="form-select">
										<option v-for="(c,inx) in countyList" :value="c.areaCode + ',' + c.areaName" :key="inx" v-show="countyList.length">
											{{ c.areaName }}
										</option>
										<option value="" v-show="!countyList.length">
										</option>
									</select>
								</div>
							</div>
						</div>

						<div class="mb-3">
							<label class="form-label">您的姓名：</label>
							<input type="text" name="lmName" v-model="company.lmName" class="form-control validate[required]"
								placeholder="真实姓名">
						</div>

						<div class="mb-3">
							<label class="form-label">您的手机号码：</label>
							<input type="text" name="phoneNumber" v-model="company.phoneNumber"
								class="form-control validate[required]" placeholder="">
						</div>

						<div class="mb-3">
							<label class="form-label">联系人固定电话（含区号）</label>
							<input type="text" name="lmTel" class="form-control" placeholder="如：053181238123" v-model="company.lmTel">
						</div>

						<div class="mb-3">
							<label class="form-label">公司联系人职务：</label>
							<input type="text" name="lmJob" v-model="company.lmJob" class="form-control mb-1" placeholder="">
							<p class="help-block">常用：
								<span class="text-primary" @click="setJob">负责人</span>
								<span class="text-primary ml-1" @click="setJob">副总</span>
								<span class="text-primary ml-1" @click="setJob">财务经理</span>
								<span class="text-primary ml-1" @click="setJob">外贸经理</span>
								<span class="text-primary ml-1" @click="setJob">经理</span>
								<span class="text-primary ml-1" @click="setJob">会计</span>
							</p>
						</div>

						<div class="form-check mb-3">
							<input class="form-check-input" type="checkbox" :value="1" id="i_am_lm" name="i_am_lm" v-model="i_am_lm">
							<label class="form-check-label" for="i_am_lm">
								我是公司联系人
							</label>
						</div>

						<div class="text-center">
							<button type="submit" class="btn btn-success" v-if="!company.id">提交绑定</button>
							<button type="submit" class="btn btn-success" v-else>更新并提交绑定</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import $ from '../../../assets/jquery-3.6.0.min.js'
import '../public/ajax_post_mp.js'
import request from '@/utils/request.js'
export default {
	data() {
		return {
			// 	company: ${ company ? no_esc},
			// i_am_lm: [${ i_am_lm }],
			company: {},
			i_am_lm: [],
			cityList: [],
			countyList: [],
			cityCode: "",
			countyCode: ""
		}
	},
	watch: {
		company(val, oldVal) {
			//this.setPca()
			//没有复制不会出发
		},
		cityList(val, oldVal) {
		},
		i_am_lm(val, oldVal) {
		},
		cityCode(val, oldVal) {
			var code = this.cityCode.split(",")[0]
			if (code && code !== 'undefined') {
				this.listCounty(code)
			}
		}
	},
	mounted() {
		if (this.company.name) {
			document.title = this.company.name
		}else{
			document.title = '新建企业'
		}
		//更新企业
		if(this.$route.query.name){
			this.company.name = this.$route.query.name
			this.getCompany()
		}

		window.saveSuccessBack = this.saveSuccessBack
		this.listCity()
		this.setPca()
	},
	methods: {
		setPca() {
			this.cityCode = this.company.cityCode + "," + this.company.cityName
			this.countyCode = this.company.countyCode + "," + this.company.countyName
		},
		setJob(e) {
			var val = $(e.target).html()
			this.$set(this.company,'lmJob',val)
		},
		getCompany() {
			let _this = this
			//没传name才清空企业id
			if(!this.$route.query.name){
				_this.company.id = null
			}
			request.get('/api/company/get/name?name=' + this.company.name)
				.then(res => {
					if (res.code === 200) {
						console.log('00000000000');
						_this.company = res.data
						if(_this.company.cityCode){
								_this.cityCode = _this.company.cityCode + "," + _this.company.cityName
						}
						if(_this.company.countyCode){
								_this.countyCode = _this.company.countyCode + "," + _this.company.countyName
						}
					}

				})
				.catch(function (error) {
					console.log(error);
				});

		},
		listCity() {
			let _this = this
			request.get('/api/pca/child/370000')
				.then(res => {
					_this.cityList = res
				})
				.catch(function (error) {
					console.log(error);
				});

		},
		listCounty(code) {
			let _this = this
			request.get('/api/pca/child/' + code)
				.then(res => {
					_this.countyList = res
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//数据提交之后的去向
		saveSuccessBack() {
			console.log("跳转")
			// 绑定企业进来的
			if(this.$route.query.cid == 0){
				this.$wx.miniProgram.navigateTo({ url: '/pages/wenjuan/index' });
				//更新企业进来的
			}else{
				const url =  '/pages/wenjuanDetails/index?cid=' + this.$route.query.cid + '&uid=' + this.$route.query.uid + "&v=" + this.$route.query.v
				this.$wx.miniProgram.redirectTo({ url });
			}
		}
	},
}

</script>
<style scoped>
.ml-1 {
	margin-left: 5px;
}
.container{
	background-color: #fff;
}
#app{
	background-color: #fff;
}
</style>